/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 16:10:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-03 10:01:51
 */
export const columns = [
    // {
    //     title: '序号',
    //     dataIndex: 'index',
    //     key: 'index',
    //     /*width:150,*/
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'index'}
    // },
    {
        title: '品牌名称',
        dataIndex: 'chName',
        key: 'chName',
        width: 200,
        align:'center',
        ellipsis:true,
        fixed: 'left',
        scopedSlots: { customRender: 'name'}
    },
    {
        title: '品牌logo',
        dataIndex: 'logo',
        key: 'logo',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'logo'}
    },
    {
        title: '关联分类',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width:300,
        align:'center',
        ellipsis:true
    },
    {
        title: '品牌描述',
        dataIndex: 'remark',
        key: 'remark',
        width:300,
        align:'center',
        ellipsis:true
    },
    {
      title: '状态',
      dataIndex: 'flagEnable',
      key: 'flagEnable',
      /*width:150,*/
      align:'center',
      ellipsis:true,
      scopedSlots: { customRender: 'flagEnable'}
    },
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      /*width:150,*/
      align: 'center',
      ellipsis: true
    },
    {
        title: '操作时间',
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        /*width:150,*/
        width: 200,
        align:'center',
        ellipsis:true
    },
    // {
    //     title: '操作',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width:150,
    //     align:'center',
    //     ellipsis:true,
    //     scopedSlots: { customRender: 'action'}
    // },
]
