<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item label="品牌名称">
              <a-input v-model="searchData.chName" placeholder="请输入品牌名称" allowClear></a-input>
            </a-form-model-item> </a-col
          ><a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="searchData.flagEnable" allowClear>
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="true">开启</a-select-option>
                <a-select-option value="false">关闭</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" icon="search" @click="getDataFilter()">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
        :scroll="{ x: 1300 }"
      >
        <!-- <span slot="flagEnable" slot-scope="item, row">
                <a-switch v-model="row.flagEnable" checked-children="启用" @change="onStatusChange(item,row)" un-checked-children="关闭" default-checked />
            </span> -->
        <span slot="name" slot-scope="text">
          <a-tooltip>
            <template slot="title"> {{ text }} </template> {{ text }}
          </a-tooltip>
        </span>
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="logo" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>

        <span slot="flagEnable" slot-scope="item, row">
          <a-tag v-if="row.flagEnable == false" color="#909399">禁用</a-tag>
          <a-tag v-if="row.flagEnable == true" color="#67c23a">在用</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="delByIds([record.id])">删除</a>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <ProductBrand-edit-modal ref="ProductBrandEditModal" @reload="getData"></ProductBrand-edit-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import ProductBrandEditModal from './components/ProductBrandEditModal.vue'
import { delProductBrand, listProductBrand, selectByIdProductBrand } from './api/ProductBrandApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productBrand',
  components: {
    ProductBrandEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      /*isEdit: checkPermission('product:spec:edit'),
      isAdd: checkPermission('product:spec:add'),
      isDelete: checkPermission('product:spec:delete'),
      isEnabled: checkPermission('product:spec:enabled'),
      isDisabled: checkPermission('product:spec:disabled'),*/
      isCheck: checkPermission('product:brand:check'),
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      listProductBrand({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    // 修改是否启动
    onStatusChange(status, row) {
      const that = this
      let num = status
      that.axios
        .post('/api/product/system/brand/editFlagEnable', { id: this.selectedRowKeys[0], flagEnable: num })
        .then((res) => {
          that.$notification.success({ message: res.message })
          this.getData()
        })
    },
    // 编辑
    handleEdit(item) {
      const _this = this
      _this.$refs.ProductBrandEditModal.setRowData(item, 'edit')
    },
    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          const res = await delProductBrand(id)
          if (res.code === 200) {
            _this.$notification.success({ message: res.message })
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
        },
        onCancel() {},
      })
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ProductBrandEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.ProductBrandEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductBrand(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.ProductBrandEditModal.setRowData(_this.selectedRows[0], 'check')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
