import { render, staticRenderFns } from "./ProductBrandEditModal.vue?vue&type=template&id=ab5ba294&scoped=true&"
import script from "./ProductBrandEditModal.vue?vue&type=script&lang=js&"
export * from "./ProductBrandEditModal.vue?vue&type=script&lang=js&"
import style0 from "./ProductBrandEditModal.vue?vue&type=style&index=0&id=ab5ba294&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab5ba294",
  null
  
)

export default component.exports